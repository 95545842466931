import React from "react"
import styled from "styled-components"
import { colors } from "../style/colors"
import { motion } from "framer-motion"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`
const Box = styled(motion.div)`
  min-width: 30px;
  height: 30px;
  margin-right: 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s ease;
  background: ${props => (props.selected ? colors.accent : "#EEEEEE")};
`
const Label = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => (props.selected ? colors.accent : "#484848")};
  margin: 0;
  transition: 0.1s ease;

  ${Container}:hover & {
    color: ${colors.accent};
  }
`

class CheckboxInput extends React.PureComponent {
  state = {
    selected: false,
  }

  handleSelect = () => {
    this.setState({ selected: !this.state.selected }, () => {
      this.props.clickHandler(this.props.label)
    })
  }

  render() {
    const { selected } = this.state
    return (
      <Container>
        <Box
          whileHover={{ scale: 1.1 }}
          selected={selected}
          onClick={() => this.handleSelect()}
        />
        <Label selected={selected}>{this.props.title}</Label>
      </Container>
    )
  }
}

export default CheckboxInput
