import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const Section = styled(motion.section)``

const Subtitle = styled(motion.h4)`
  font-weight: 400;
  font-family: "Work Sans";
  color: #646464;
  margin-bottom: 10px;
`
const SelectionTitle = styled(motion.h1)`
  font-size: 7vw;

  @media (max-width: 800px) {
    font-size: 13vw;
  }
`

const IntroSection = ({ title }) => (
  <Section>
    <Subtitle
      initial={{ opacity: 0, y: -15 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        y: { duration: 1.8 },
        opacity: { duration: 2, velocity: 5 },
      }}
    >
      You've selected:
    </Subtitle>
    <SelectionTitle
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 1.5,
        opacity: { duration: 2.3, velocity: 10 },
      }}
    >
      {title}
    </SelectionTitle>
  </Section>
)

export default IntroSection
