import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavContainer = styled.nav`
  box-shadow: 0 2px 4px rgba(218, 218, 218, 0.5);
  height: 70px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
  display: ${props => props.home && "none"};
`
const InnerContainer = styled.div`
  height: 100%;
  max-width: ${props => props.max && "1000px"};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 5px 50px;
`
const Logo = styled.img`
  width: 150px;
  margin: 0;
`

class Navbar extends Component {
  state = {}
  render() {
    return (
      <NavContainer home={this.props.home}>
        <InnerContainer max={this.props.max}>
          <Link to="/">
            <Logo src={require("../images/logo.png")} />
          </Link>
        </InnerContainer>
      </NavContainer>
    )
  }
}

export default Navbar
